

export const msalConfigTest = {
    auth: {
        clientId: "27536e54-580c-4f49-af75-16984b779456",
        authority: "https://login.microsoftonline.com/rolande.nl",
        redirectUri: "http://localhost"
        
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
}

export const msalConfigProd = {
    auth: {
        clientId: "76048d58-4a55-4394-8712-d0ba1b503c02",
        authority: "https://login.microsoftonline.com/rolande.nl",
        redirectUri: "https://emailtool.rolande.nl"
        
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
}
   