import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import logo from './images/rolande.png';
import { msalConfigTest, msalConfigProd } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";


const axiosInstance = axios.create()

const msalAppTest = new PublicClientApplication(msalConfigTest);
const msalAppProd = new PublicClientApplication(msalConfigProd);

const isProduction = window.location.hostname === 'emailtool.rolande.nl';
const msalApp = isProduction ? msalAppProd : msalAppTest;

axiosInstance.interceptors.request.use(async config => {
    try {
        const account = msalApp.getActiveAccount();
        if (!account) {
            console.error("No active account found");
            throw new Error("No active account. User must log in.");
        }
        const request = {
            scopes: ["User.Read"],
            account: account
        };
        const tokenResponse = await msalApp.acquireTokenSilent(request);
        config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        // config.headers.Authorization = `Bearer ${API_SECRET}`;
    } catch (error) {
        console.error("Error acquiring token:", error);
        // Handle errors here, for example by redirecting to login
        throw error;  // This will prevent the request from proceeding
    }
    return config;
});



export default function MailingListTable() {
    const [emails, setEmails] = useState([]);
    const [stations, setStations] = useState([]);
    const [search, setSearch] = useState("");
    const [alertMap, setAlertMap] = useState({});

    const isProduction = window.location.hostname === 'emailtool.rolande.nl';
    console.log("isProduction: " + isProduction)

    const mailListEndpoint = isProduction ? `/api/mailinglist` : 'http://localhost:5000/api/mailinglist'
    const stationsEndPoint = isProduction ? `/api/stations` : 'http://localhost:5000/api/stations'
    const alertListEndPoint = isProduction ? `/api/alertlist` : 'http://localhost:5000/api/alertlist'
    const csvEndPoint = isProduction ? `/api/download_csv` : 'http://localhost:5000/api/download_csv'
    console.log("rerendered at the top")


    async function fetchData() {
        console.log("fetchData called")
        const responses = await Promise.all([
            axiosInstance.get(mailListEndpoint).catch(err => {
                console.error('Error mailListEndpoint:', err.response ? err.response.data : err.message);
            }),
            axiosInstance.get(stationsEndPoint).catch(err => {
                console.error('Error stationsEndPoint:', err.response ? err.response.data : err.message);
            }),
            axiosInstance.get(alertListEndPoint).catch(err => {
                console.error('Error alertListEndPoint:', err.response ? err.response.data : err.message);
            })
        ]);
        

        const [emailsRes, stationsRes, alertsRes] = responses;

        let updatedStationsRes = stationsRes.data.slice(); // Create a copy of the array
        updatedStationsRes.unshift({ station_name: "Pricing", has_cng: false }); // Add the item to the beginning


        setEmails(emailsRes.data);
        setStations(updatedStationsRes);
        // Transform alerts data into a map
        const fetchedAlertMap = alertsRes.data.reduce((acc, alert) => {
            const key = `${alert.email}|${alert.station}|${alert.fueltype}`;
            acc[key] = alert;
            return acc;
        }, {});

        setAlertMap(fetchedAlertMap);

    }

    useEffect(() => {
        console.log("useEffect called")
        fetchData();
    }, []);

    function handleEmailDelete(mailKey, email_to_delete) {

        console.log("handleEmailDelete called")
        deleteEmail(mailKey);
        // Remove all alerts associated with the email from alertMap
        const updatedAlertMap = Object.keys(alertMap).reduce((newAlertMap, key) => {
            if (!key.startsWith(email_to_delete)) {
                newAlertMap[key] = alertMap[key];
            }
            return newAlertMap;
        }, {});

        setAlertMap(updatedAlertMap);
        setEmails(emails.filter(email => email.mail_key !== mailKey)); //remove from emaillist        
    }

    async function deleteEmail(mailKey) {
        console.log("deleteEmail called")
        await axiosInstance.delete(mailListEndpoint + `/${mailKey}`); //removes the email and all alerts from the sql table
    }

    function confirmEmailDelete(mailKey, email_to_delete) {
        console.log("confirmEmailDelete called")
        const confirmDelete = window.confirm("Are you sure you want to delete this email and all its active alerts?");
        if (confirmDelete) {
            handleEmailDelete(mailKey, email_to_delete);
        }
    }

    async function handleEmailAdd() {
        console.log("handleEmailAdd called")
        const email = prompt('Please enter an email address:');
        if (email) {
            if (emails.some(e => e.email === email)) {
                alert(`The email "${email}" already exists in the mailing list.`);
            } else {
                await axiosInstance.post(mailListEndpoint, { email })
                    .then(res => {
                        setEmails([...emails, { 'email': res.data.email, 'mail_key': res.data.id }]);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
    }

    function handleCheckboxClick(email, station, fuelType, isChecked) {
        console.log("handleCheckboxClick called")
        if (station === "Pricing") {
            fuelType = 'None';
        }
        const key = `${email}|${station}|${fuelType}`;

        if (isChecked) {
            // Add new alert
            const id = uuidv4();
            try {
                const newAlert = { email, station, fueltype: fuelType, id: id };
                setAlertMap(prevMap => ({ ...prevMap, [key]: newAlert }));
                let res;
                res = axiosInstance.post(alertListEndPoint, newAlert)
                    .then(console.log(res));

            }

            catch (err) {
                console.log(err)
            }

        } else {
            // Remove existing alert
            if (alertMap[key]) {
                setAlertMap(prevMap => {
                    const newMap = { ...prevMap };
                    delete newMap[key];
                    return newMap;
                });

                let res;
                res = axiosInstance.delete(alertListEndPoint + `/${alertMap[key].id}`)
                    .then(console.log(res));
            }
        }
    }

    function handleDownloadCSV() {
        console.log("handleDownloadCSV called")
        axiosInstance.get(csvEndPoint, {
            responseType: 'blob', // Tells Axios to expect a blob (binary) response
        }).then((response) => {
            // Create a Blob URL from the binary response
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            // Create a link element to download the file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Mailinglistimport.csv');

            // Add the link to the page and click it to start the download
            document.body.appendChild(link);
            link.click();
        });
    }

    const filtered_emails = emails.filter((item) =>
        item.email.toLowerCase().includes(search.toLowerCase())
    );

    async function handleDownloadEmails() {
        const stationFueltype = document.getElementById("station|fueltype").value.split('|');
        const selectedStation = stationFueltype[0];
        const selectedFueltype = stationFueltype[1] || 'None';

        console.log(selectedStation)
        console.log(selectedFueltype)

        // Filter the alertMap object to get the relevant email addresses
        const relevantEmails = Object.keys(alertMap).reduce((emailList, key) => {
            const [email, station, fueltype] = key.split('|');
            if (station === selectedStation && fueltype === selectedFueltype) {
                emailList.push(email);
            }
            return emailList;
        }, []);

        const uniqueEmails = [...new Set(relevantEmails)];

        // Copy to clipboard
        navigator.clipboard.writeText(uniqueEmails.join('; ')).then(() => {
            alert('Emails copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    }

    return (
        <>

            <div className="flex flex-row px-20 py-10">
                <div className="basis-1/2">
                    <div  >
                        <img src={logo} alt="Rolande logo" />
                    </div>
                </div>
                <div className="basis-1/2">
                    <button
                        className="px-4 py-2 rounded-md bg-blue-500 text-white mr-4"
                        onClick={handleEmailAdd}
                    >
                        Add Email
                    </button>

                    <input
                        type="text"
                        placeholder="Search email addresses..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="w-full md:w-auto border border-gray-400 rounded-md px-4 py-2 mr-4"
                    />

                    <button
                        className="px-4 py-2 rounded-md bg-green-500 text-white"
                        onClick={handleDownloadCSV}
                    >
                        Download CSV
                    </button>
                </div>


                <div className="basis-1/2">
                    <select
                        id="station|fueltype"
                        className="border border-gray-400 rounded-md px-4 py-2 mr-4"
                    >
                        {stations.map(station => (
                            <>
                                <option
                                    key={`${station.station_name}${station.station_name !== "Pricing" ? "|LNG" : ""}`}
                                    value={`${station.station_name}${station.station_name !== "Pricing" ? "|LNG" : ""}`}
                                >
                                    {`${station.station_name}${station.station_name !== "Pricing" ? " LNG" : ""}`}
                                </option>
                                {station.has_cng && station.station_name !== "Pricing" && (
                                    <option key={`${station.station_name}|CNG`} value={`${station.station_name}|CNG`}>{`${station.station_name} CNG`}</option>
                                )}
                            </>
                        ))}
                    </select>

                    <button
                        className="px-4 py-2 rounded-md bg-green-500 text-white"
                        onClick={handleDownloadEmails}
                    >
                        Copy emails to clipboard
                    </button>
                </div>

            </div>







            <div className="flex-grow overflow-auto h-[800px]">
                <table className="table-auto min-w-full">
                    <thead>
                        <tr>
                            <th className="border-l sticky left-0 top-0 bg-white z-30">Email</th>
                            {stations.map(station => (
                                <th className="border-l sticky top-0 bg-white z-20" key={station.station_name} colSpan={station.has_cng ? 2 : 1}>
                                    {station.station_name}
                                </th>
                            ))}
                            <th className="border-l sticky right-0 top-0 bg-white z-20">Email Delete</th>
                        </tr>
                        {stations.some(station => station.has_cng) && (
                            <tr>
                                <th className="sticky left-0 z-10 px-6 py-3 bg-white border-b border-gray-600 z-30"></th>
                                {stations.map(station => (
                                    <React.Fragment key={station.station_name}>
                                        {station.station_name === "Pricing" ? (
                                            <th className="px-12 border-l sticky top-[30px] bg-white z-10"></th>
                                        ) :
                                            station.has_cng ? (
                                                <>
                                                    <th className="pl-4 pr-2 border-l sticky top-[30px] bg-white z-10">LNG</th>
                                                    <th className="pr-4 sticky top-[30px] bg-white z-10">CNG</th>
                                                </>
                                            ) : (
                                                <th className="px-12 border-l sticky top-[30px] bg-white z-10">LNG</th>
                                            )}
                                    </React.Fragment>
                                ))}
                                <th className="sticky right-0 z-10 px-6 py-3 bg-white border-b border-gray-600"></th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {filtered_emails.map(email => (
                            <tr key={email.mail_key}>
                                <td className="sticky text-left left-0 z-10 px-6 py-3 bg-white border-b border-gray-600">{email.email}</td>
                                {stations.map(station => (
                                    <React.Fragment key={`${email.email}|${station.station_name}`}>
                                        <td className="border-l">
                                            {station.station_name !== "Pricing" && (
                                                <input
                                                    className="rounded-full h-4 w-4"
                                                    type="checkbox"
                                                    checked={alertMap[`${email.email}|${station.station_name}|LNG`] !== undefined}
                                                    onChange={e => handleCheckboxClick(email.email, station.station_name, 'LNG', e.target.checked)}
                                                />
                                            )}

                                            {station.station_name === "Pricing" && (
                                                <input
                                                    className="rounded-full h-4 w-4"
                                                    type="checkbox"
                                                    checked={alertMap[`${email.email}|${station.station_name}|None`] !== undefined}
                                                    onChange={e => handleCheckboxClick(email.email, station.station_name, 'None', e.target.checked)}
                                                />
                                            )}
                                        </td>

                                        {station.has_cng === 1 && station.station_name !== "Pricing" && (
                                            <td>
                                                <input
                                                    className="rounded-full h-4 w-4"
                                                    type="checkbox"
                                                    checked={alertMap[`${email.email}|${station.station_name}|CNG`] !== undefined}
                                                    onChange={e => handleCheckboxClick(email.email, station.station_name, 'CNG', e.target.checked)}
                                                />
                                            </td>
                                        )}
                                    </React.Fragment>
                                ))}
                                <td className="sticky right-0 z-10 px-6 py-3 bg-white border-b border-gray-600"><button className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 focus:outline-none focus:shadow-outline-red" onClick={() =>
                                    confirmEmailDelete(email.mail_key, email.email)
                                }
                                >
                                    Delete
                                </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>

    );
}
