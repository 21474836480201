import { msalConfigTest, msalConfigProd } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { useState } from "react";
import MailingListTable from './mailinglisttable';
import logo from './images/rolande.png';

const msalAppTest = new PublicClientApplication(msalConfigTest);
const msalAppProd = new PublicClientApplication(msalConfigProd);


function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState({});


    console.log(window.location.hostname)
    const isProduction = window.location.hostname === 'emailtool.rolande.nl';
    isProduction ? console.log("We are using prod msal") : console.log("We are using test msal");
    const msalApp = isProduction ? msalAppProd : msalAppTest;
    

    async function login() {
        try {
            const response = await msalApp.loginPopup({
                scopes: ['user.read', 'openid', 'profile'],
                prompt: "select_account"
            });
            msalApp.setActiveAccount(response.account);
            setIsAuthenticated(true);
        } catch (err) {
            setIsAuthenticated(false);
            setUser({});
            setError(err);
        }
    }

    function logoutuser() {
        msalApp.logout();
    }

    return (
        <div className="App">
            {isAuthenticated ? (
                <MailingListTable />
            ) : (

                <div className="flex flex-col justify-center items-center h-screen">
                    <div className="mb-8">
                        <img src={logo} alt="Rolande logo" />
                    </div>
                    <div className="flex justify-center">
                    <button onClick={() => login()} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Login
                    </button>
                </div>
            </div>
            )}
        </div>
    );
}

export default App;
